import React, { Component, Fragment } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

import BannerH4 from '../components/share/banner/bannerH4'
import BannerH6 from '../components/share/banner/bannerH6'
import BrandBanner from '../components/share/brandBanner'
import TypeFormButton from '../components/share/typeFormButton'
import ContentWrapper from '../components/share/contentWrapper'
import FooterBox from '../components/share/footerBox'
import ImageBackground from '../components/share/imageBackground'
import IntroRow from '../components/share/introRow'
import Layout from '../components/layout'
import ProductBoxes from '../components/share/productBoxes'
import Quote from '../components/share/quote'
import SpeakToTeam from '../components/share/speakToTeam'

import customMedia from '../utils/customMedia'

const query = graphql`
  query {
    header: file(relativePath: { eq: "migrating/header.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    matthewBourn: file(relativePath: { eq: "brandAgentIcon/mathewbourn.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    crm: file(relativePath: { eq: "marketing/crm.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    trackable: file(relativePath: { eq: "marketing/trackable.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    supportBackground: file(
      relativePath: { eq: "share/supportBackground.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    supportPhone: file(relativePath: { eq: "index/supportPhone.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    rank: file(relativePath: { eq: "campaignManagement/rank.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    services: file(relativePath: { eq: "services/services.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
class MigratingPage extends Component {
  render() {
    return (
      <Layout>
        <StaticQuery
          query={query}
          render={data => (
            <Fragment>
              <ImageBackground
                background={data.header.childImageSharp.fluid}
                style={{ height: '600px' }}
                opacity="0"
                skew
              >
                <ContentWrapper style={{ flexWrap: 'nowrap' }}>
                  <HeaderTitleStrike>But change is hard...</HeaderTitleStrike>
                  <HeaderTitle style={{ fontStyle: 'italic' }}>
                    Not Anymore.
                  </HeaderTitle>
                  <HeaderBody>
                    We’ve moved across <strong>entire national groups</strong>{' '}
                    and individual offices <strong>of every size</strong> and{' '}
                    <strong>every unique workflow</strong> you can imagine.
                    There’s no job too big for our dedicated onboarding teams.
                  </HeaderBody>
                  <HeaderBody>In fact, we love a challenge.</HeaderBody>
                  <TypeFormButton text="REQUEST A DEMO" />
                </ContentWrapper>
              </ImageBackground>
              <ContentWrapper style={{ alignItems: 'center' }}>
                <BannerH4>
                  So, what's the deal? Why is everyone flocking to{' '}
                  <strong>join Realhub</strong> in the new year,{' '}
                  <strong>in the middle of spring</strong> or anytime of year at
                  all?
                </BannerH4>
                <BannerH6>
                  It's simple, the offer is too good to refuse. The advantages
                  massively outweighing the potential pains inherent with making
                  changes.
                </BannerH6>
              </ContentWrapper>
              <ContentWrapper>
                <BrandBanner text="A few of the amazing teams we've helped migrate." />
              </ContentWrapper>
              <ContentWrapper>
                <Quote
                  agentImage={data.matthewBourn.childImageSharp.fluid}
                  desc={`"We’ve grown, we’ve developed and we’ve evolved into a
                      bigger and better agency than what we were last year,
                      supported by the team at Realhub."`}
                  agencyName="McconnellBourn"
                  title="Principal & Director"
                  agentName="Matthew Bourn"
                />
              </ContentWrapper>
              <ContentWrapper>
                <IntroRow
                  image={data.crm.childImageSharp.fluid}
                  linkText="See more abouty the artwork builder..."
                  link="/design-content"
                  reverse={true}
                  content={
                    <Fragment>
                      <Paragraph
                        style={{ fontSize: '16px', fontWeight: 'bold' }}
                      >
                        2x your marketing production efficiency
                      </Paragraph>
                      <Paragraph>
                        We’ve built our tools around ease of use, and by
                        capturing your data from areas like your CRM, and
                        automatically populating this information across all
                        your marketing, you can create bulk marketing campaigns
                        in minutes.
                      </Paragraph>
                      <Paragraph>
                        Utilise exceptional add-ons like using cross-campaign
                        information to populate recently sold marketing, build
                        comparable property marketing and input agent data with
                        ease.
                      </Paragraph>
                      <Paragraph>
                        We’ve combined efficient artwork production with the
                        ability to order from almost every real estate supplier
                        in the country, on the one platform, with one system to
                        learn and one place to monitor your entire offices
                        marketing spend.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>
              <ContentWrapper>
                <IntroRow
                  image={data.trackable.childImageSharp.fluid}
                  content={
                    <Fragment>
                      <Paragraph
                        style={{ fontSize: '16px', fontWeight: 'bold' }}
                      >
                        Reduce mistakes, reduce communication confusion and hold
                        one single source of truth across your campaign.
                      </Paragraph>
                      <Paragraph>
                        Don’t risk making a huge mistake, use our in-built
                        proofing and artwork approval workflows to ensure the
                        right version gets approved before sending to suppliers.
                      </Paragraph>
                      <Paragraph>
                        Public (with the vendor) and privately (within your
                        team) comment in the same place as the artworks.
                      </Paragraph>
                      <Paragraph>
                        Across expensive and high-risk newspaper publications
                        all the way through to simple social media and digital
                        ads increased clarity and reduced mistakes available
                        across desktop and mobile.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>
              <ImageBackground
                background={data.supportBackground.childImageSharp.fluid}
                height="700px"
                opacity="0.5"
              >
                <ContentWrapper>
                  <IntroRow
                    title="Seriously Talented, Fast Support"
                    linkText="Why are we different?"
                    link="/support"
                    content={
                      <Fragment>
                        <Paragraph>
                          Making a wide-spread process change across your office
                          will always include some risk. While you can't ever
                          truly predict the issues that might arise, you don’t
                          have to worry when you know you’ve got a team on call
                          that can solve problems fast and train your staff in
                          how to improve their daily output.
                        </Paragraph>

                        <Paragraph>
                          <strong>
                            It takes the majority of our customers less than a
                            day to learn the basics, and start improving their
                            offices marketing efficiency.
                          </strong>
                        </Paragraph>
                        <Paragraph>
                          <strong>
                            Our service response time is on average under 1
                            hour.
                          </strong>
                        </Paragraph>

                        <Paragraph>
                          <strong>
                            We offer support level SLAs on enterprise contracts.
                          </strong>
                        </Paragraph>
                      </Fragment>
                    }
                  />
                  <SupportPhone
                    fluid={data.supportPhone.childImageSharp.fluid}
                    style={{ position: 'absolute' }}
                  />
                </ContentWrapper>
              </ImageBackground>
              <ContentWrapper style={{ marginTop: '70px' }}>
                <IntroRow
                  image={data.rank.childImageSharp.fluid}
                  linkText="Read more about brandhub..."
                  link="/brands"
                  content={
                    <Fragment>
                      <Paragraph
                        style={{ fontSize: '16px', fontWeight: 'bold' }}
                      >
                        Enterprise level security and user control
                      </Paragraph>
                      <Paragraph>
                        From single office use to multi-national brands, control
                        over your entire team is an essential and sometime
                        tedious management responsibility.
                      </Paragraph>
                      <Paragraph>
                        With Realhub we’ve made tools that enable complete
                        oversight for brand administrators and granular fine
                        detail control over user permissions and restrictions.
                      </Paragraph>
                      <Paragraph>
                        Every office, every brand has very different workflows
                        and requirements, our detailed controls and policies
                        make managing any team simple.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>
              <ContentWrapper>
                <IntroRow
                  image={data.services.childImageSharp.fluid}
                  linkText="Read more about services..."
                  link="/services"
                  reverse={true}
                  content={
                    <Fragment>
                      <Paragraph
                        style={{ fontSize: '16px', fontWeight: 'bold' }}
                      >
                        Extensive supplier/partners network
                      </Paragraph>
                      <Paragraph>
                        Built to enable providers from any niche area of real
                        estate marketing to receive orders and artworks and
                        providing unequaled tools that enable them to operate
                        smoothly, and feed information like signboard install
                        photos and job completion back into the platform.
                      </Paragraph>
                      <Paragraph>
                        Realhub benefits from a network effect where every user
                        on the platform increases the benefits for every other
                        user as we build out tools and grow our supplier network
                        to encompass every provider in the country.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>
              <ContentWrapper>
                <FlexColumn style={{ alignItems: 'center' }}>
                  <BannerH4 style={{ marginBottom: 0 }}>
                    Get <strong>every marketing product</strong> in the{' '}
                    <strong>one place</strong>, and <strong>save hours</strong>{' '}
                    communicating and tracking entire campaigns.
                  </BannerH4>
                </FlexColumn>
              </ContentWrapper>

              <ContentWrapper>
                <ProductBoxes />
              </ContentWrapper>

              <ContentWrapper contentRef={el => (this.requestDemo = el)}>
                <SpeakToTeam />
              </ContentWrapper>

              <ContentWrapper>
                <FlexColumn style={{ alignItems: 'center' }}>
                  <BannerH4>
                    We’re already <strong>powering your competition.</strong>
                  </BannerH4>
                  <BannerH6>
                    Don’t get <strong>left behind.</strong>
                  </BannerH6>
                  <TypeFormButton />
                </FlexColumn>
              </ContentWrapper>

              <ContentWrapper direction="row">
                <FooterBox name="migration" />

                <FooterBox name="feetUp" />
              </ContentWrapper>
            </Fragment>
          )}
        />
      </Layout>
    )
  }
}

export default MigratingPage

const HeaderTitleStrike = styled.h1`
  font-size: 34px;
  font-weight: 300;

  &:after {
    content: '';
    position: absolute;
    height: 10px;
    width: 92%;
    left: -10px;
    top: 23px;
    border-top: 2px solid #ffffff;
    border-radius: 50%;
  }

  ${customMedia.greaterThan('mobile')`
    max-width: 680px;
    font-size: 53px;

    &:after {
      width: 80%;
      left: -15px;
      top: 37px;
      border-top: 3px solid #ffffff;
      border-radius: 50%;
    }
  `};

  ${customMedia.greaterThan('tablet')`
  &:after {
    width: 65%;
    left: -15px;
    top: 37px;
    border-top: 3px solid #ffffff;
    border-radius: 50%;
  }
`};
`

const HeaderTitle = styled.h1`
  font-size: 34px;
  font-weight: 300;
  margin-bottom: 30px;

  ${customMedia.greaterThan('mobile')`
    max-width: 680px;
    font-size: 53px;
  `};
`

const HeaderBody = styled.p`
  font-size: 14px;
  font-weight: 300;
  font-family: Open Sans;
  line-height: 1.5;
  margin-bottom: 30px;

  ${customMedia.greaterThan('mobile')`
    max-width: 450px;
  `};
`

const Paragraph = styled.p`
  font-family: Open Sans;
  margin-bottom: 30px;
`

const SupportPhone = styled(Img)`
  position: absolute;
  overflow: hidden;
  width: 200px;
  height: 200px;
  bottom: -142px;
  right: -10px;

  ${customMedia.between('mobile', 'tablet')`
    width: 350px;
    height: 350px;
    bottom: -300px;
    right: -50px;
  `};

  ${customMedia.greaterThan('desktop')`
    width: 350px;
    height: 350px;
    bottom: -300px;
    right: -150px;
  `};
`
const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
