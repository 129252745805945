import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import ProductBox from '../share/productBox'

import productsData from '../../data/products'

import customMedia from '../../utils/customMedia'

const query = graphql`
  query {
    proposals: file(
      relativePath: { eq: "ProductTiles/background/proposals.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 250) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    campaigns: file(
      relativePath: { eq: "ProductTiles/background/campaigns.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 250) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    marketing: file(
      relativePath: { eq: "ProductTiles/background/marketing.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 250) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    socialMedia: file(
      relativePath: { eq: "ProductTiles/background/socialMedia.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 250) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    designContent: file(
      relativePath: { eq: "ProductTiles/background/designContent.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 250) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    marketplace: file(
      relativePath: { eq: "ProductTiles/background/marketplace.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 250) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    brands: file(relativePath: { eq: "ProductTiles/background/brands.png" }) {
      childImageSharp {
        fluid(maxWidth: 250) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    finance: file(relativePath: { eq: "ProductTiles/background/finance.png" }) {
      childImageSharp {
        fluid(maxWidth: 250) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    mobileAgent: file(
      relativePath: { eq: "ProductTiles/background/mobileAgent.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 250) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    support: file(relativePath: { eq: "ProductTiles/background/support.png" }) {
      childImageSharp {
        fluid(maxWidth: 250) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

const ProductBoxes = props => {
  return (
    <StaticQuery
      query={query}
      render={data => {
        return (
          <Wrapper>
            {productsData.map(product => (
              <ProductBox
                key={product.shortName}
                width="100%"
                title={product.title}
                icon={product.icon}
                background={data[product.shortName].childImageSharp.fluid}
                link={product.link}
              />
            ))}
          </Wrapper>
        )
      }}
    />
  )
}

export default ProductBoxes

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;

  ${customMedia.greaterThan('desktop')`
    justify-content: ${props => props.align || 'flex-start'};
  `};
`
