import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import customMedia from '../../utils/customMedia'

function calcBoxLength(
  rowItems = 5,
  boxMargin = '0px',
  adjustHeight = '0px',
  width = '768px'
) {
  return `
    calc((${width} / ${rowItems}) - (${rowItems} - 1) * ${boxMargin} / ${rowItems} - ${adjustHeight})
  `
}

const ProductBox = ({
  background,
  icon,
  title,
  link,
  ...other
}) => {
  return (
    <Wrapper to={link} {...other}>
      <Overlay />
      {background && <BackgroundImg fluid={background} />}
      <Content>
        <Icons>
          <Icon icon={icon} />
          <IconHover icon={['fal', 'arrow-circle-right']} />
        </Icons>

        <GoTo>GO TO</GoTo>
        <div>{title}</div>
      </Content>
    </Wrapper>
  )
}

ProductBox.propTypes = {
  background: PropTypes.object,
  icon: PropTypes.array,
  title: PropTypes.string,
  rowItems: PropTypes.string,
  boxMargin: PropTypes.string,
}

export default ProductBox

const Content = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  letter-spacing: 2px;
  text-align: center;
  font-size: 10px;
  z-index: 3;
`

const Icons = styled.div`
  position: relative;
  width: 50px;
  height: 50px;
`

const Icon = styled(FontAwesomeIcon)`
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 50px;
  height: 50px;
  font-size: 30px;
  transition: opacity 0.3s, transform 0.3s;
`

const IconHover = styled(Icon)`
  transform: rotate(-180deg) scale(0.5);
  opacity: 0;
`

const GoTo = styled.div`
  opacity: 0;
`

const Overlay = styled.div`
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${props => props.backgroundColor || 'rgba(0, 0, 0, 0.6)'};
  z-index: 2;
`

const Wrapper = styled(Link)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${props => props.backgroundColor};
  margin-right: ${props => props.boxMargin || '0px'};
  margin-bottom: ${props => props.boxMargin || '0px'};
  color: ${props => props.color || '#ffffff'};
  width: ${props => calcBoxLength(2, props.boxMargin, '0px', '90vw')};
  height: ${props =>
    calcBoxLength(2, props.boxMargin, props.adjustHeight, '90vw')};

  ${customMedia.lessThan('mobile')`
    &:nth-child(2n) {
      margin-right: 0;
    };
  `};

  ${customMedia.between('mobile', 'tablet')`
    width: ${props => calcBoxLength(props.rowItems, props.boxMargin)};
    height: ${props =>
      calcBoxLength(props.rowItems, props.boxMargin, props.adjustHeight)};

    &:nth-child(${props => props.rowItems}n) {
      margin-right: 0;
    };
  `};

  ${customMedia.greaterThan('desktop')`
    width: ${props => calcBoxLength(props.rowItems, props.boxMargin)};
    height: ${props =>
      calcBoxLength(props.rowItems, props.boxMargin, props.adjustHeight)};

    &:nth-child(${props => props.rowItems}n) {
        margin-right: 0;
    }

    &:hover {
      cursor: pointer;

      ${GoTo} {
        transition: opacity 0.3s;
        opacity: 1;
      };

      ${Icon} {
        transform: rotate(180deg) scale(.5);
			  opacity: 0;
      };

      ${IconHover} {
        transform: rotate(0deg) scale(1);
			  opacity: 1;
      };

      ${Overlay} {
        transition: background-color 0.3s;
        background-color: rgba(0, 0, 0, 0.85);
      };
    };
  `};
`

const BackgroundImg = styled(Img)`
  width: 100%;
  height: 100%;
`
