import React, { Fragment } from 'react'
import styled from 'styled-components'

const footerData = [
  {
    name: 'migration',
    link: '/case-studies',
    title: 'Migration Specialists.',
    buttonText: 'Read Our Case Studies ',
    content: () => (
      <Fragment>
        <Paragraph>
          Being a young company, a lot of our customers are coming from existing
          solutions. This has made us experts at migrating customers from other
          platforms.
        </Paragraph>

        <Paragraph>
          We’ve onboarded entire national groups in under a month, and in many
          cases custom built solutions to ensure the process is as seamless and
          fast as possible.
        </Paragraph>

        <Paragraph>
          Any size office, <strong>change is easy.</strong>
        </Paragraph>
      </Fragment>
    ),
  },
  {
    name: 'feetUp',
    link: '/services',
    title: 'Put your feet up, we’ve got you.',
    buttonText: 'Our Services',
    content: () => (
      <Fragment>
        <Paragraph>
          You’ll be wishing you’d changed sooner. More efficient marketing
          staff, more informed agents. Happier vendors. Everything is within
          reach when supported by a team that genuinely cares about the success
          off your agency.
        </Paragraph>

        <Paragraph>
          We take support seriously, our entire business is built around putting
          you first, and benefiting from the network effects of a large platform
          with multiple feedback sources.
        </Paragraph>
      </Fragment>
    ),
  },
  {
    name: 'newHight',
    link: '/services',
    title: 'Proposals at new heights',
    buttonText: 'SIGN UP TODAY',
    content: () => (
      <Fragment>
        <Paragraph>
          You can literally build these things anywhere you’ve got an internet
          connection. The more proposals you send, the more listings you can
          win. There’s 2 ways to increase your GCI. Win more listings or
          increase your fees. We’ve lowered our fees and made proposals
          available for free, now it’s up to you to start sending out winning
          proposals.
        </Paragraph>

        <Paragraph>
          <strong>Start making more, today.</strong>
        </Paragraph>
      </Fragment>
    ),
  },
]

export default footerData

const Paragraph = styled.p`
  font-family: Open Sans;
  margin-bottom: 15px;
`
