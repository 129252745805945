import { productMedia } from '../components/share/mediaImport'

const productsData = [
  {
    mediaHover: productMedia.productArtworkBuilderHover,
    icon: ['fal', 'file-invoice-dollar'],
    iconHover: productMedia.productArtworkBuilderIconHover,
    title: 'PROPOSALS',
    shortName: 'proposals',
    link: '/proposals',
    active: true,
  },
  {
    mediaHover: productMedia.productBrandProtectionHover,
    icon: ['fal', 'home'],
    iconHover: productMedia.productBrandProtectionIconHover,
    title: 'CAMPAIGNS',
    shortName: 'campaigns',
    link: '/campaigns',
    active: true,
  },
  {
    mediaHover: productMedia.productBrandWideReportingHover,
    icon: ['fal', 'paint-brush'],
    iconHover: productMedia.productBrandWideReportingIconHover,
    title: 'MARKETING',
    shortName: 'marketing',
    link: '/marketing',
    active: true,
  },
  {
    mediaHover: productMedia.productCampaignManagementHover,
    icon: ['fal', 'thumbs-up'],
    iconHover: productMedia.productCampaignManagementIconHover,
    title: 'SOCIAL MEDIA',
    shortName: 'socialMedia',
    link: 'social-media',
    active: true,
  },
  {
    mediaHover: productMedia.productDeadlineAssistantHover,
    icon: ['fal', 'palette'],
    iconHover: productMedia.productDeadlineAssistantIconHover,
    title: 'DESIGN/CONTENT',
    shortName: 'designContent',
    link: 'design-content',
    active: true,
  },
  {
    mediaHover: productMedia.productInvoicingAndAccountingHover,
    icon: ['fal', 'shopping-basket'],
    iconHover: productMedia.productInvoicingAndAccountingIconHover,
    title: 'MARKETPLACE',
    shortName: 'marketplace',
    link: '/marketplace',
    active: true,
  },
  {
    mediaHover: productMedia.productNewspaperHover,
    icon: ['fal', 'code-branch'],
    iconHover: productMedia.productNewspaperIconHover,
    title: 'BRANDS',
    shortName: 'brands',
    link: '/brands',
    active: true,
  },
  // {
  //   mediaHover: productMedia.productOneClickPortalsHover,
  //   iconURL: productMedia.productOneClickPortalsIcon,
  //   iconHover: productMedia.productOneClickPortalsIconHover,
  //   title: 'ONE CLICK PORTALS',
  // },
  {
    mediaHover: productMedia.productPrintManagementHover,
    icon: ['fal', 'box-usd'],
    iconHover: productMedia.productPrintManagementIconHover,
    title: 'FINANCE',
    shortName: 'finance',
    link: '/finance',
    active: true,
  },
  {
    mediaHover: productMedia.productProposalBuilderHover,
    icon: ['fal', 'mobile'],
    iconHover: productMedia.productProposalBuilderIconHover,
    title: 'MOBILE AGENT',
    shortName: 'mobileAgent',
    link: '/mobile-agent',
    active: true,
  },
  {
    mediaHover: productMedia.productQuotingHover,
    icon: ['fal', 'headset'],
    iconHover: productMedia.productQuotingIconHover,
    title: 'SUPPORT',
    shortName: 'support',
    link: '/support',
    active: true,
  },
  // {
  //   mediaHover: productMedia.productToolkitHover,
  //   iconURL: productMedia.productToolkitIcon,
  //   iconHover: productMedia.productToolkitIconHover,
  //   title: 'FIND OUT MORE…',
  // },
]

export default productsData
