import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { navigate } from 'gatsby-link'
import Img from 'gatsby-image'
import styled from 'styled-components'

import customMedia from '../../utils/customMedia'
import BaseButton from './button'
import footerData from '../../data/footer'

const query = graphql`
  query {
    migration: file(relativePath: { eq: "index/migration.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    feetUp: file(relativePath: { eq: "index/feetUp.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    newHight: file(relativePath: { eq: "proposals/new_hight.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const FooterBox = ({ name }) => {
  const item = footerData.find(i => i.name === name)
  const { title, content, buttonText, link } = item

  return (
    <StaticQuery
      query={query}
      render={data => (
        <Wrapper onClick={() => navigate(link)}>
          <TopImg fluid={data[name].childImageSharp.fluid} />
          <Body>
            <Title>{title}</Title>
            <Content>{content()}</Content>
            {buttonText && (
              <Button
                href={link}
                text={buttonText}
                ghost
                style={{ alignSelf: 'flex-start', marginTop: 'auto' }}
              />
            )}
          </Body>
        </Wrapper>
      )}
    />
  )
}

FooterBox.propTypes = {
  name: PropTypes.string.isRequired,
}

export default FooterBox

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 90%;
  margin: 10px 10px;
  border-radius: 15px;
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.05);
  text-decoration: none;
  color: #4a4a4a;
  background-color: white;
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }

  ${customMedia.between('mobile', 'tablet')`
    width: 70%;
    height: 520px;
    min-height: 300px;
  `};

  ${customMedia.greaterThan('desktop')`
    width: 70%;
    height: 500px;
  `};
`

const TopImg = styled(Img)`
  width: 100%;
  min-height: 200px;
  max-height: 200px;
  border-radius: 15px 15px 0 0;
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;

  ${customMedia.between('mobile', 'tablet')`
    min-height: 300px;
  `};

  ${customMedia.greaterThan('desktop')`
    height: 300px;
  `};
`

const Title = styled.dt`
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 10px;
`

const Content = styled.dd`
  text-align: left;
  font-size: 0.8rem;
  line-height: 1.5;
  margin-bottom: 0;

  ${customMedia.between('mobile', 'tablet')`
    font-size: 0.85rem;
    height: 300px;
  `};

  ${customMedia.greaterThan('desktop')`
    font-size: 12px;
    line-height: 20px;
  `};
`

const Button = styled(BaseButton)`
  align-self: flex-start;
`
